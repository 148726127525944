import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { APIResponse, KYCRequest } from "../lib/typings";



export const useUserVerification = (id: string) => {
    return useQuery<any, any, APIResponse<KYCRequest>, any>({
        queryKey: ['user-verification', id],
        queryFn: async () => {
            return (await axios.get(`/admin/user-verification/${id}`)).data;
        }
    })
}
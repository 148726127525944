import { useQuery } from "@tanstack/react-query"
import axios from "axios"


export const useUserList = (cursor?: number) => {
    return useQuery({
        queryKey: ['users', cursor],
        queryFn: async () => {
            return (await axios.get(`/admin/users?count=20${cursor ? `&cursor=${cursor}` : ''}`)).data
        }
    })
}
import { ReactNode, useReducer } from "react";
import { AppContext } from "./AppContext";
import { AppReducer, InitialAppReducerState } from "./AppReducer";


interface AppContextProviderProps {
    children: ReactNode;
};

const AppContextProvider = (props: AppContextProviderProps): JSX.Element => {

    const [state, dispatch] = useReducer(AppReducer, InitialAppReducerState, () => InitialAppReducerState)
    const { children } = props;
    const value = { state, dispatch }
    return (
        <>
            <AppContext.Provider value={value}>
                {children}
            </AppContext.Provider>
        </>
    )
}

export default AppContextProvider;
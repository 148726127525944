import { useQuery } from "@tanstack/react-query"
import axios from "axios";
import { APIResponse } from "../lib/typings";


export const useLottery = (id: string) => {
    return useQuery<any, any, APIResponse<any>, any>({
        queryKey: ['lottery', id],
        queryFn: async () => {
            return (await axios.get(`/admin/lotteries/${id}`)).data;
        }
    });
}
import { Breadcrumb, Card, Spin, Toast, Typography } from "@douyinfe/semi-ui";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";
import { useUser } from "../../hooks/useUser";
import { NavLink, useParams } from "react-router-dom";
import { useCallback } from "react";
import { format } from "date-fns";
import RecentUserTasks from "../../components/RecentUserTasks";
import { Helmet } from "react-helmet";
import RecentUserPayouts from "../../components/RecentUserPayouts";


interface UserDetailProps { };

/**
 * shows the details of a user
 * @param props 
 * @returns 
 */
const UserDetail = (props: UserDetailProps): JSX.Element => {
    const { userId } = useParams();
    const { data, isLoading, isError, isSuccess } = useUser(userId as string)

    const render = useCallback(() => {
        if (isSuccess) {
            if (data.success) {
                Toast.success(data.message)
                return (
                    <>
                        <Helmet>
                            <title>@{data ? data.data?.username : ""} - User | Droplime</title>
                        </Helmet>
                        <Breadcrumb>
                            <NavLink to={`/dashboard/users`} >
                                <Breadcrumb.Item>Users</Breadcrumb.Item>
                            </NavLink>
                            <Breadcrumb.Item>{data.data?.username}</Breadcrumb.Item>
                        </Breadcrumb>
                        <Typography.Title>{data.data?.username}</Typography.Title>
                        <Typography.Text>Created on {format(new Date(data.data?.create_time as string), 'dd/MM/yyyy')}</Typography.Text>
                        <div className={`grid grid-cols-12`}>
                            <div className={`col-span-8`}>
                                
                            </div>
                            <div className={`col-span-4 space-y-4`}>
                                <RecentUserTasks userId={userId as string}/>
                                <RecentUserPayouts userId={userId as string}/>
                            </div>
                        </div>
                    </>
                )
            } else {
                Toast.error(data.message)
                return (
                    <>
                    </>
                )
            }
        } else {
            return <Spin />
        }
    }, [isLoading, isError, isSuccess])

    return (
        <>
            {render()}
        </>
    )
}

export default withPrimaryLayout(UserDetail, {
    authenticated: true,
});
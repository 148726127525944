import { Button, Form, ArrayField, Toast } from "@douyinfe/semi-ui";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";
import { useChestManifestCreate } from "../../hooks/useChestManifestCreate";
import { customRequestArgs } from "@douyinfe/semi-ui/lib/es/upload";
import axios from "axios";


interface NewScratchCardManifestProps { };

const NewScratchCardManifest = (props: NewScratchCardManifestProps): JSX.Element => {

    const { mutate, isError, isSuccess, isLoading } = useChestManifestCreate()

    const submit = async (values: any) => {
        mutate(values, {
            onSuccess: () => {
                Toast.success(`manifest created`)
            },
            onError: () => {
                Toast.error(`failed to create manifest`)
            }
        })
    }

    const uploadFile = async ({ file, fileInstance, action, onProgress, onError, onSuccess }: customRequestArgs) => {
        const res = (await axios.post(`/admin/media/presign`)).data;
        const r = await axios.put(res.URL, file, {
            headers: {
                "Content-Type": fileInstance.type
            },
            onUploadProgress: (e) => {
                //  Show progress
                // var percentCompleted = Math.round((e.loaded * 100) / e.total as number);
                onProgress({
                    loaded: e.loaded,
                    total: e.total as number
                })
            },
        });
        onSuccess(r, new Event(`upload`))
    }

    return (
        <>
            <main>
                <Form onSubmit={submit}>
                    <Form.Input label={`Title`} field={`title`} />
                    <Form.TextArea label={`description`} field={`description`} />
                    <Form.Upload label={`Image URL`} field={`image_url`} action={`/`} />
                    <Form.InputNumber label={`Rows`} field={`rows`} min={1} />
                    <Form.InputNumber label={`Columns`} field={`columns`} min={1} />
                    <ArrayField field={`symbols`}>
                        {({ add, arrayFields, addWithInitValue }) => (
                            <>
                                {arrayFields.map(({ field, key, remove }, i) => (
                                    <>
                                        <div key={key} className="gap-y-3">
                                            <Form.Upload
                                                label={`Image`}
                                                field={`${field}.image`}
                                                helpText={`An image of the symbol`}
                                                action="/"
                                                customRequest={uploadFile}
                                                className="w-full"
                                                accept={`image/webp`}
                                            />
                                            <Form.InputNumber
                                                label={`Weight`}
                                                helpText={`The weight associated with the symbol`}
                                                field={`${field}.weight`}
                                                size={`large`}
                                                className="w-full"
                                            />
                                            <Form.InputNumber
                                                label={`Value`}
                                                helpText={`The value of the symbol`}
                                                field={`${field}.value`}
                                                min={1}
                                                size={`large`}
                                                className="w-full"
                                            />
                                        </div>
                                    </>
                                ))}
                            </>
                        )}
                    </ArrayField>
                    <Button htmlType="submit" onClick={submit}>Create manifest</Button>
                </Form>
            </main>
        </>
    )
}

export default withPrimaryLayout(NewScratchCardManifest, {
    authenticated: true,
});
import { useQuery } from "@tanstack/react-query"
import { APIResponse } from "../lib/typings"
import axios from "axios"



export const useRaffle = (id: string) => {
    return useQuery<any, any, APIResponse<any>, any>({
        queryKey: ['raffle', id],
        queryFn: async () => {
            return (await axios.get(`/admin/raffles/${id}`)).data;
        }
    })
}
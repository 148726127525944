import { useQuery } from "@tanstack/react-query"
import axios from "axios"


export const usePromoCodeList = (cursor?: number) => {
    return useQuery({
        queryKey: ['promo-codes', cursor],
        queryFn: async () => {
            return (await axios.get(`/admin/promo-codes?count=20${cursor ? '&cursor=${cursor}' : ''}`)).data
        },
        keepPreviousData: true,
    })
}
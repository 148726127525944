import { List, Spin, Typography } from "@douyinfe/semi-ui";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";
import { useUserList } from "../../hooks/useUserList";
import { useCallback } from "react";
import { NavLink } from "react-router-dom";
import { format } from "date-fns";

interface UserListProps { };

/**
 * displays a list of all users
 * @param props 
 * @returns 
 */
const UserList = (props: UserListProps): JSX.Element => {

    const { data, isError, isSuccess, refetch, isLoading } = useUserList();

    const render = useCallback(() => {
        if (isLoading) {
            return <Spin />
        } else if (isSuccess) {
            return (
                <>
                    <List
                        dataSource={data.data}
                        renderItem={(item, idx) => (
                            <>
                                <NavLink key={idx} to={`/dashboard/users/${item.id}`}>
                                    <List.Item

                                        main={(
                                            <>
                                                <div>
                                                    <Typography.Text strong>@{item.username}</Typography.Text>
                                                    <div>
                                                        <Typography.Text>Signed up at {format(new Date(item?.create_time as string), 'HH:mm - dd/MM/yyyy')}</Typography.Text>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    />
                                </NavLink>
                            </>
                        )}
                    />
                </>
            )
        } else if (isError) {

        } else {
            return (
                <>

                </>
            )
        }
    }, [isLoading, isSuccess, isError])

    return (
        <>
            <Typography.Title>Users</Typography.Title>
            {render()}
        </>
    )
}

export default withPrimaryLayout(UserList, {
    authenticated: true
});
import { ArrayField, Button, Card, Form, Typography } from "@douyinfe/semi-ui";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";
import DistributionTypeSelect from "../../components/Select/DistributionTypeSelect";


interface NewChestProps { };

/**
 * allows a user to create a chest
 * @param props 
 * @returns 
 */
const NewChest = (props: NewChestProps): JSX.Element => {
    return (
        <>
            <Form className="w-3/5 mx-auto">
                {({ formState, formApi }) => (
                    <>
                        <section className="pb-6">
                            <Typography.Title >New Chest Manifest</Typography.Title>
                            <Typography.Text>Create a new chest manifest. This will allow users to receive in-app items within the chest.</Typography.Text>
                        </section>
                        <Card>
                            <Form.Input label="Title" field={`title`} size={`large`} />
                            <Form.TextArea label="Description" field={`description`} />
                        </Card>
                        <Typography.Title heading={2} className="py-4">Schema</Typography.Title>
                        <Card>
                            <ArrayField field='items' initValue={[]}>
                                {({ add, arrayFields, addWithInitValue }) => (
                                    <>
                                        {arrayFields.map(({ field, key, remove }, i) => (
                                            <>
                                                <div key={key} className="gap-y-3">
                                                    <Form.InputNumber
                                                        label={`Weight`}
                                                        field={`${field}.weight`}
                                                        size={`large`}
                                                        className="w-full"
                                                    />
                                                    <DistributionTypeSelect
                                                        field={`${field}.distribution_type`}
                                                    />
                                                    {formApi.getValue(`${field}.distribution_type`) === `discrete_range` && (
                                                        <>
                                                            <Form.Select
                                                                label={`Values`}
                                                                field={`${field}.range_values`}
                                                                filter multiple allowCreate
                                                                className="w-full"
                                                            />
                                                        </>
                                                    )}
                                                    {formApi.getValue(`${field}.distribution_type`) === `continuous` && (
                                                        <>
                                                            <Form.InputNumber
                                                                label={`Minimum value`}
                                                                field={`${field}.min`}
                                                                className="w-full"
                                                                min={0}
                                                            />
                                                            <Form.InputNumber
                                                                label={`Maximum value`}
                                                                field={`${field}.max`}
                                                                className="w-full"
                                                            />
                                                        </>
                                                    )}
                                                    {formApi.getValue(`${field}.distribution_type`) === `weighted_interval` && (
                                                        <>
                                                            <ArrayField field={`${field}.interval_values`} initValue={[]}>
                                                                {({ add: a, arrayFields, addWithInitValue }) => (
                                                                    <>
                                                                        {arrayFields.map(({ field: f, key: kdx, remove: r }) => (
                                                                            <>
                                                                                <div key={kdx}>
                                                                                    <Form.InputNumber label={`Minimum`} field={`${f}.min`} className="w-full" />
                                                                                    <Form.InputNumber label={`Maximum`} field={`${f}.max`} className="w-full" />
                                                                                    <Form.InputNumber label={`Weight`} field={`${f}.weight`} className="w-full" />
                                                                                    <div className="py-3 flex justify-end">
                                                                                        <Button theme={`solid`} type={`danger`} onClick={r}>Remove</Button>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        ))}
                                                                        <Button onClick={a}>New interval value</Button>
                                                                    </>
                                                                )}
                                                            </ArrayField>
                                                        </>
                                                    )}
                                                    <div className="flex justify-end">
                                                        <Button
                                                            theme={`solid`}
                                                            type={`danger`}
                                                            onClick={remove}
                                                            className="mt-3">
                                                            Remove item
                                                        </Button>
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                        <Button onClick={add} className="py-3">Add an item</Button>
                                    </>
                                )}
                            </ArrayField>
                        </Card>
                        <div className="pt-5">
                            <Button htmlType="submit">Create chest</Button>
                        </div>
                    </>
                )}
            </Form>
        </>
    )
}

export default withPrimaryLayout(NewChest, {
    authenticated: true
});
import { useState } from "react";
import { useGameList } from "../../hooks/useGameList";
import { Button, Typography } from "@douyinfe/semi-ui";
import { NavLink } from "react-router-dom";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";
import { Helmet } from 'react-helmet';

interface GamesListProps { };

const GamesList = (props: GamesListProps): JSX.Element => {

    const [page, setPage] = useState<number>(0);

    const { data, isLoading, isError, isSuccess } = useGameList();

    return (
        <>
            <Helmet>
                <title>Games | Droplime</title>
            </Helmet>
            <header>
                <Typography.Title>Games</Typography.Title>
                <NavLink to={`/dashboard/games/new`}>
                    <Button>Add a new game</Button>
                </NavLink>
            </header>
            <main>
                
            </main>
        </>
    )
}

export default withPrimaryLayout(GamesList, {
    authenticated: true
});
import { Button, Typography } from "@douyinfe/semi-ui";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useAppContext } from "../hooks/useAppContext";


interface NavbarProps { };

const Navbar = (props: NavbarProps): JSX.Element => {

    const navigate = useNavigate();
    const { dispatch } = useAppContext();

    const items = [
        {
            title: `Dashboard`,
            href: `/dashboard`
        },
        {
            title: `Chests`,
            href: `/dashboard/chests`
        },
        {
            title: `Games`,
            href: `/dashboard/games`
        },
        {
            title: `Promo Codes`,
            href: `/dashboard/promo-codes`
        },
        {
            title: `Revenue`,
            href: `/dashboard/revenue`
        },
        {
            title: `Lotteries`,
            href: `/dashboard/lotteries`
        },
        {
            title: `Notifications`,
            href: `/dashboard/notifications`
        },
        {
            title: `Awards`,
            href: `/dashboard/awards`
        },
        {
            title: `Raffles`,
            href: `/dashboard/raffles`
        },
        {
            title: `Rewards`,
            href: `/dashboard/rewards`
        },
        {
            title: `Payouts`,
            href: `/dashboard/payouts`
        },
        {
            title: `Users`,
            href: `/dashboard/users`
        },
        {
            title: `KYC`,
            href: `/dashboard/kyc`
        }
    ]

    const signOut = () => {
        delete axios.defaults.headers.common.Authorization;
        dispatch({ type: `auth/logout` })
        navigate('/login', { replace: true,  })
    }

    return (
        <>
            <nav className={`grid grid-cols-12 mb-10 drop-shadow-md border-b py-3 px-3`}>
                <div className={`col-span-2`}>
                    <img src={`/logo.svg`} width={32} height={32} />
                </div>
                <div className={`col-span-8`}>
                    <div className={`flex justify-evenly my-auto`}>
                        {items.sort((a, b) => a.title.charCodeAt(0) - b.title.charCodeAt(0)).map((i, idx) => (
                            <>
                                <Link key={idx} to={i.href}>
                                    <div className="my-auto">
                                        <Typography.Text strong>{i.title}</Typography.Text>
                                    </div>
                                </Link>
                            </>
                        ))}
                    </div>
                </div>
                <div className={`col-span-2 flex justify-end`}>
                    <Button onClick={signOut} theme="solid" type={`danger`}>Sign out</Button>
                </div>
            </nav>
        </>
    )
}

export default Navbar;
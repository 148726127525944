import { Button, Form, Spin, Typography } from "@douyinfe/semi-ui";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useRaffle } from "../../hooks/useRaffle";
import { useCallback } from "react";


interface RaffleDetailProps { };

const RaffleDetail = (props: RaffleDetailProps): JSX.Element => {

    const { raffleId } = useParams();
    const { data, isLoading, isError, isSuccess } = useRaffle(raffleId as string);

    const render = useCallback(() => {
        if (isLoading) {
            return <Spin />
        } else if (isError) {
            return <Typography.Text>Something went wrong. Please reload the page.</Typography.Text>
        } else if (isSuccess) {
            return (
                <>
                    <Form onSubmit={submit}>
                        <Form.Input field={`title`} initValue={data.data.title}/>
                        <Form.Input field={`id`} noLabel hidden />
                        <Button htmlType={`submit`}>Generate winner</Button>
                    </Form>
                </>
            )
        } else {
            return <Typography.Text>An unknown error occurred. Please reload the page.</Typography.Text>
        }
    }, [isLoading, isError, isSuccess])

    const submit = () => {

    }

    return (
        <>
            <Helmet>
                <title>Raffle | Droplime</title>
            </Helmet>
            {render()}
        </>
    )
}

export default withPrimaryLayout(RaffleDetail, {
    authenticated: true,
});
import { Button, Card, Form, Typography } from "@douyinfe/semi-ui";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";
import { Helmet } from "react-helmet";


interface NewRaffleProps { };

const NewRaffle = (props: NewRaffleProps): JSX.Element => {
    return (
        <>
            <Helmet>
                <title>New Raffle | Droplime</title>
            </Helmet>
            <main className="w-3/5 mx-auto">
                <div className="pb-5">
                    <Typography.Title>New Raffle</Typography.Title>
                </div>
                <Form className="space-y-4">
                    <Card>
                        <Form.Input label={`Title`} field={`title`} />
                        <Form.TextArea label={`Description`} field={`description`} />
                    </Card>
                    <Card title={`Requirements`}>

                    </Card>
                    <Card title={`Time`}>
                        <Form.DatePicker label={`Starts at`} type={`dateTime`} field={`start_time`} />
                        <Form.DatePicker label={`Ends at`} type={`dateTime`} field={`end_time`} />
                    </Card>
                    <Button htmlType={`submit`}>Create raffle</Button>
                </Form>
            </main>
        </>
    );
}

export default withPrimaryLayout(NewRaffle, {
    authenticated: true,
});
import jwt_decode from "jwt-decode";

type UserRole = `user` | `admin` | `owner`;

export interface IAppReducerState {
    /** user id */
    id?: number;
    /** user role */
    role?: UserRole;
    /** authentication state */
    authenticated: boolean;
}

export const InitialAppReducerState: IAppReducerState = {
    authenticated: false,
}

export type AppReducerAction =
| { type: "auth/login", token: string }
| { type: "auth/logout" }

export const AppReducer = (state: IAppReducerState, action: AppReducerAction): IAppReducerState => {
    switch (action.type) {
        case `auth/login`: {

            const data = jwt_decode<any>(action.token)

            const role = data.role as UserRole;

            if (role === `user`) {
                return state;
            }

            return {
                ...state,
                id: data.id,
                role: role,
                authenticated: true,
            }
        }
        case `auth/logout`: {
            return {
                ...state,
                id: undefined,
                role: undefined,
                authenticated: false
            }
        }
        default: {
            return state;
        }
    }
}
import { Card, List, Spin, Typography } from "@douyinfe/semi-ui";
import { useUserTasksList } from "../hooks/useUserTasksList";
import { useCallback } from "react";
import { format } from "date-fns";


interface RecentUserTasksProps {
    userId: string;
};

const RecentUserTasks = (props: RecentUserTasksProps): JSX.Element => {

    const { data, isLoading, isError, isSuccess, refetch } = useUserTasksList(props.userId, 10)

    const render = useCallback(() => {
        if (isLoading) {
            return <Spin/>
        } else if (isError) {
            return <Typography.Text>Failed to fetch tasks</Typography.Text>
        } else if (isSuccess) {
            return (
                <>
                    <List
                        dataSource={data.data}
                        renderItem={(item, idx) => (
                            <>
                                <List.Item
                                    key={idx}
                                    main={(
                                        <>
                                            <article>
                                                <div>
                                                    <Typography.Text>{item.task_name}</Typography.Text>
                                                </div>
                                                <div>
                                                    <Typography.Text>{format(new Date(item.create_time as string), 'HH:mm, dd/MM/yyyy')}</Typography.Text>
                                                </div>
                                            </article>
                                        </>
                                    )}
                                />
                            </>
                        )}
                    />
                </>
            )
        } else {
            return (
                <>
                    <Typography.Text>Unhandled state</Typography.Text>
                </>
            )
        }
    }, [isLoading, isError, isSuccess])

    return (
        <>
            <Card title={`Recent tasks`}>
                {render()}
            </Card>
        </>
    )
}

export default RecentUserTasks;
import { Button, Card, Form, Typography } from "@douyinfe/semi-ui";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";
import { useEffect, useState } from "react";
import { FormApi } from "@douyinfe/semi-ui/lib/es/form";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { Helmet } from "react-helmet";


interface NewLotteryProps { };

/**
 * creates a new lottery
 */
const NewLottery = (props: NewLotteryProps): JSX.Element => {

    const { mutate, isLoading, isSuccess, isError } = useMutation({
        mutationFn: async (input: any) => {
            axios.post('/lotteries', JSON.stringify(input), {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        }
    })

    const submit = async (values: any) => {
        mutate(values)
    }

    useEffect(() => {
        if (isSuccess) {

        }
    }, [isLoading])

    return (
        <>
            <Helmet>
                <title>New Lottery | Droplime</title>
            </Helmet>
            <main className={`p-5 md:p-0 md:w-3/5 mx-auto`}>
                <Typography.Title className={`pb-5`}>New Lottery</Typography.Title>
                <Form onSubmit={submit} className={`space-y-4`}>
                    <Card>
                        <Form.Input
                            label={`Title`}
                            field={`title`}
                            required
                            size={`large`}
                            disabled={isLoading}
                            className="w-full"
                        />
                        <Form.InputNumber
                            label={`Jackpot amount`}
                            field={`jackpot`}
                            min={0}
                            size={`large`}
                            required
                            disabled={isLoading}
                            className="w-full"
                        />
                        <Form.InputNumber
                            label={`Maximum number`}
                            field={`max_number`}
                            min={30}
                            helpText={`The maximum number that users will select to starting from 1.`}
                            size={`large`}
                            required
                            disabled={isLoading}
                            className="w-full"
                        />
                        <Form.InputNumber
                            label={`Number of selections`}
                            field={`num_selections`}
                            min={1}
                            helpText={`The number of selections the user makes on each entry, excluding the bonus selection.`}
                            size={`large`}
                            required
                            disabled={isLoading}
                            className="w-full"
                        />
                        <Form.InputNumber
                            label={`Maximum bonus number`}
                            field={`max_bonus_number`}
                            required
                            disabled={isLoading}
                            className="w-full"
                        />
                        <Form.Select
                            label={`Winning weights`}
                            helpText={`The weights for winning combinations. The chosen weights must add to 100.`}
                            allowCreate
                            filter
                            multiple
                            field={`weights`}
                            className="w-full"
                        />
                        <Form.InputNumber
                            label={`Ticket price`}
                            field={`ticket_price`}
                            min={1}
                            size={`large`}
                            required
                            disabled={isLoading}
                            className="w-full"
                        />
                    </Card>
                    <Card>
                        <Form.DatePicker
                            label={`Start time`}
                            type={`dateTime`}
                            field={`start_time`}
                            size={`large`}
                            disabled={isLoading}
                            className="w-full"
                        />
                        <Form.DatePicker
                            label={`End time`}
                            type={`dateTime`}
                            field={`end_time`}
                            size={`large`}
                            disabled={isLoading}
                            className="w-full"
                        />
                        <Form.DatePicker
                            label={`Draw time`}
                            type={`dateTime`}
                            field={`draw_time`}
                            size={`large`}
                            className="w-full"
                            disabled={isLoading}
                        />
                    </Card>
                    <div className="mt-5">
                        <Button
                            htmlType={`submit`}
                            theme={`solid`}
                            loading={isLoading}
                        >Create lottery</Button>
                    </div>
                </Form>
            </main>
        </>
    )
}

export default withPrimaryLayout(NewLottery, {
    authenticated: true,
});
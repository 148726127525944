import { useQuery } from "@tanstack/react-query"
import axios from "axios"


export const useGameList = (cursor?: number) => {
    return useQuery({
        queryKey: ['games', cursor],
        queryFn: async () => {
            return (await axios.get(`/admin/games?count=20${cursor ? `&cursor=${cursor}`: ''}`)).data
        }
    })
}
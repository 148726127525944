import { useQueries, useQuery } from "@tanstack/react-query"
import axios from "axios"
import { APIResponse, KYCRequestList } from "../lib/typings"



export const useUserVerificationList = (count: number = 20, cursor?: number) => {
    return useQuery<any, any, APIResponse<KYCRequestList>, any>({
        queryKey: ['user-verification', 'list', count, cursor],
        queryFn: async () => {
            return (await axios.get(`/admin/user-verification?count=${count}${cursor ? `&cursor=${cursor}`: ''}`)).data
        }
    })
}
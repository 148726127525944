import { Button, Card, Form, Spin, Toast, Typography } from "@douyinfe/semi-ui";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";
import { usePromoCode } from "../../hooks/usePromoCode";
import { useParams } from "react-router-dom";
import { useCallback } from "react";


interface PromoCodeDetailProps { };

const PromoCodeDetail = (props: PromoCodeDetailProps): JSX.Element => {

    const { promoCodeId } = useParams();
    const { data, isLoading, isError, isSuccess } = usePromoCode(promoCodeId as string);

    const render = useCallback(() => {
        if (isSuccess) {
            Toast.info(data.message)
            if (data.success) {
                return (
                    <>
                        <Form>
                            <Card>
                                
                            </Card>
                            <Button htmlType="submit">Update promo code</Button>
                        </Form>
                    </>
                )
            } else {
                return (
                    <>

                    </>
                )
            }
        } else if (isLoading) {
            return (
                <>
                    <Spin />
                </>
            )
        }
        else if (isError) {
            return (
                <>
                    <Typography.Text>Something went wrong. Please reload the page.</Typography.Text>
                </>
            )
        }
        else {
            return (
                <>
                    <Typography.Text>Unknown state</Typography.Text>
                </>
            )
        }
    }, [isLoading, isError, isSuccess])

    return (
        <>
            <div className="pb-5">
                <Typography.Title>Promo Code Detail</Typography.Title>
                {render()}
            </div>
        </>
    )
}

export default withPrimaryLayout(PromoCodeDetail, {
    authenticated: true,
});
import { Button, Card, Form, Typography } from "@douyinfe/semi-ui";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";


interface NewPromoCodeProps { };

const NewPromoCode = (props: NewPromoCodeProps): JSX.Element => {
    return (
        <>
            <main className="w-3/5 mx-auto">
                <div className="pb-5">
                    <Typography.Title>New Promo Code</Typography.Title>
                </div>
                <Form className="space-y-4">
                    <Card>
                        <Form.Input label={`Title`} field={`title`} size="large" className="w-full" />
                        <Form.TextArea label={`Description`} field={`description`} />
                        <Form.Switch label={`Is limited supply?`} field={`is_limited_supply`} className="w-full" />
                        <Form.InputNumber label={`Total supply`} size="large" min={1} field={`total_supply`} className="w-full" />
                        <Form.Input label={`Code`} field={`code`} size="large" className="w-full" />
                        <Form.InputNumber label={`Value`} field={`value`} size="large" className="w-full" />
                        <Form.DatePicker label={`Expiry`} type={`dateTime`} field={`expire_time`} size="large" className="w-full" />
                    </Card>
                    <Button theme="solid" type={`primary`} htmlType="submit">Create code</Button>
                </Form>
            </main>
        </>
    )
}

export default withPrimaryLayout(NewPromoCode, {
    authenticated: true,
});
import { useMutation } from "@tanstack/react-query"
import axios from "axios"

/**
 * allows a user to create a game
 */
export const useGameCreate = () => {
    return useMutation({
        mutationKey: ['games'],
        mutationFn: async (input) => {
            return (await axios.post(`/admin/games`)).data
        }
    })
}
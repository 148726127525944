import { Button, Form } from "@douyinfe/semi-ui";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";


interface VerificationAcceptButtonProps {
    userId: number;
};

const VerificationAcceptButton = (props: VerificationAcceptButtonProps): JSX.Element => {

    const navigate = useNavigate();

    const { mutate, isLoading, isSuccess, isError } = useMutation({
      mutationKey: ['user-verification', props.userId],
      mutationFn: async () => {
        return (await axios.post(`/admin/user-verification/${props.userId}/accept`)).data
      }
    })

    const submit = () => {
      mutate();
    }

    return (
        <>
          <Form onSubmit={submit}>
            <Button htmlType="submit" disabled={isLoading}>Approve verification</Button>
          </Form>
        </>
    )
}

export default VerificationAcceptButton;
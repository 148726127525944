import { Button, Typography } from "@douyinfe/semi-ui";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";
import { Link } from "react-router-dom";


interface RewardsProps { };

const Rewards = (props: RewardsProps): JSX.Element => {
    return (
        <>
            <Typography.Title>Rewards</Typography.Title>
            <Link to={`/rewards/new`}>
                <Button>Create new reward</Button>
            </Link>
        </>
    )
}

export default withPrimaryLayout(Rewards);
import { Button, Typography } from "@douyinfe/semi-ui";
import { NavLink } from "react-router-dom";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";
import { Helmet } from "react-helmet";


interface LotteryListProps { };


/**
 * displays a list of lotteries
 * @param props 
 * @returns 
 */
const LotteryList = (props: LotteryListProps): JSX.Element => {
    return (
        <>
            <Helmet>
                <title>Lotteries | Droplime</title>
            </Helmet>
            <div className={`pb-5`}>
                <Typography.Title>Lotteries</Typography.Title>
            </div>
            <NavLink to={`/dashboard/lotteries/new`}>
                <Button>New lottery</Button>
            </NavLink>
        </>
    )
}

export default withPrimaryLayout(LotteryList, {
    authenticated: true,
});
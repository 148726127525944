import { Typography } from "@douyinfe/semi-ui";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";
import { Helmet } from "react-helmet";


interface RevenueDashboardProps {};

const RevenueDashboard = (props: RevenueDashboardProps): JSX.Element => {
    return (
        <>
            <Helmet>
                <title>Revenue | Droplime</title>
            </Helmet>
            <Typography.Title>Revenue</Typography.Title>
        </>
    )
}

export default withPrimaryLayout(RevenueDashboard, {
    authenticated: true
});
import { Button, Card, Form, Typography } from "@douyinfe/semi-ui";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";


interface NewPushNotificationProps { };

const NewPushNotification = (props: NewPushNotificationProps): JSX.Element => {

    const submit = () => {
        
    }

    return (
        <>
            <Typography.Title>New Push Notification</Typography.Title>
            <Typography.Text>Send a push notification to users</Typography.Text>
            <main>
                <Form className="space-y-4">
                    <Card title={`Notification`}>

                    </Card>
                    <Button htmlType="submit">Send notification</Button>
                </Form>
            </main>
        </>
    )
}

export default withPrimaryLayout(NewPushNotification, {
    authenticated: true,
});
import { Button, Card, Form, Typography } from "@douyinfe/semi-ui";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";


interface NewInboxNotificationProps { };

const NewInboxNotification = (props: NewInboxNotificationProps): JSX.Element => {
    return (
        <>
            <main className="w-3/5 mx-auto">
                <header className="pb-4">
                    <Typography.Title>New Inbox Notification</Typography.Title>
                    <Typography.Text>Send a message to all users inboxes</Typography.Text>
                </header>
                <Form className="space-y-4">
                    <Card>
                        <Form.Input label={`Title`} field={`title`} />
                        <Form.TextArea label={`Content`} field={`content`} />
                    </Card>
                    <Button htmlType="submit" theme="solid">Create inbox notification</Button>
                </Form>
            </main>
        </>
    )
}

export default withPrimaryLayout(NewInboxNotification, {
    authenticated: true
});
import { useQuery } from "@tanstack/react-query"
import axios from "axios";
import { APIResponse } from "../lib/typings";


/**
 * fetches a specific promo code
 * @param id the ID of the promo code to fetch
 * @returns 
 */
export const usePromoCode = (id: string) => {
    return useQuery<any, any, APIResponse<any>, any>({
        queryKey: ['promo-codes'],
        queryFn: async () => {
            const res = await axios.get(`/admin/promo-codes/${id}`);
            return res.data;
        }
    });
}
import { List, Spin, Typography } from "@douyinfe/semi-ui";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";
import { useUserVerificationList } from "../../hooks/useUserVerificationList";
import { useCallback } from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";


interface UserVerificationListProps { };

const UserVerificationList = (props: UserVerificationListProps): JSX.Element => {

    const { data, isLoading, isError, isSuccess } = useUserVerificationList();

    const render = useCallback(() => {
        if (isSuccess) {
            if (data.success) {
                return (
                    <>
                        <List
                            dataSource={data.data}
                            renderItem={(i, idx) => (
                                <>
                                    <NavLink to={`/dashboard/kyc/${i.id}`}>
                                        <article key={idx}>
                                            <Typography.Text strong>{i.username}</Typography.Text>
                                        </article>
                                    </NavLink>
                                </>
                            )}
                        />
                    </>
                )
            } else {
                return (
                    <>
                        <Typography.Text>{data.message}</Typography.Text>
                    </>
                )
            }
        } else if (isLoading) {
            return <Spin />
        } else if (isError) {
            return <Typography.Text>An error occurred. Please reload the page.</Typography.Text>
        }
    }, [isLoading, isError, isSuccess])

    return (
        <>
            <Helmet>
                <title>KYC Requests | Droplime</title>
            </Helmet>
            <Typography.Title>KYC requests</Typography.Title>
            {render()}
        </>
    )
}

export default withPrimaryLayout(UserVerificationList, {
    authenticated: true
});
import { Button, Card, Form, Typography } from "@douyinfe/semi-ui";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";


interface NewEmailNotificationProps {};

const NewEmailNotification = (props: NewEmailNotificationProps): JSX.Element => {
    return (
        <>
            <Typography.Title>New Email Notification</Typography.Title>
            <Typography.Text>Send an email to users</Typography.Text>
            <Form className="space-y-4">
                <Card title={`Email contents`}>
                    <Form.Input label={`Title`} field={`title`}/>
                    <Form.Select allowCreate filter multiple field={`recipients`}/>
                    <Form.Input label={`Sender`} field={`sender`} suffix={`@droplime.com`}/>
                    <Form.TextArea label={`Contents`} field={`contents`}/>
                </Card>
                <Button htmlType={`submit`}>Send email</Button>
            </Form>
        </>
    )
}

export default withPrimaryLayout(NewEmailNotification, {
    authenticated: true,
});
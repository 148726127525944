import { Button, Form, Input, Toast } from "@douyinfe/semi-ui";
import { FormApi } from "@douyinfe/semi-ui/lib/es/form";
import { useState } from "react";
import { signIn } from "../lib/droplime";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../hooks/useAppContext";
import { Helmet } from "react-helmet";


interface LoginPageProps { };

const LoginPage = (props: LoginPageProps): JSX.Element => {

    const [form, setForm] = useState<FormApi>()
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const { state, dispatch } = useAppContext();

    const submit = async (values: any) => {
        setIsLoading(true)
        try {
            const payload = await signIn(values);
            dispatch({ type: `auth/login`, token: payload.access_token })
            axios.defaults.headers.common.Authorization = `Bearer ${payload.access_token}`;
            navigate('/dashboard')
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false)
            Toast.error('Failed to sign in')
        }
    }

    return (
        <>
            <Helmet>
                <title>Sign in to Orchard | Droplime</title>
            </Helmet>
            <div className="h-screen flex">
                <div className="m-auto">
                    <Form onSubmit={submit}>
                        <Form.Input 
                            type={`email`} 
                            label={`Email`} 
                            field={`email`} 
                            name="email"
                            disabled={isLoading}
                        />
                        <Form.Input 
                            field={`password`} 
                            mode={`password`} 
                            label={`Password`} 
                            disabled={isLoading}
                        />
                        <Button
                            htmlType="submit"
                            theme={`solid`}
                            type={`primary`}
                            size="large"
                            loading={isLoading}
                            className="w-full">Sign in</Button>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default LoginPage;
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { APIResponse, User } from "../lib/typings";


export const useUser = (userId: string) => {
    return useQuery<null, unknown, APIResponse<User>, any>({
        queryKey: ['users', userId],
        networkMode: `always`,
        cacheTime: 0,
        queryFn: async () => {
            return (await axios.get(`/admin/users/${userId}`)).data;
        }
    })
}
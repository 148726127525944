import { useQuery } from "@tanstack/react-query"
import axios from "axios"


export const useScratchCardManifest = (id: string) => {
    return useQuery({
        queryKey: ['scratch-card-manifests', id],
        queryFn: async () => {
            return (await (axios.get(`${process.env.REACT_APP_API_ENDPOINT}/admin/scratch-card-manifests/${id}`))).data
        }
    })
}
import { useParams } from "react-router-dom";
import { useScratchCardManifest } from "../../hooks/useScratchCardManifest";
import { useCallback } from "react";
import { Form, Spin, Typography } from "@douyinfe/semi-ui";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";


interface ScratchCardManifestDetailProps {};

const ScratchCardManifestDetail = (props: ScratchCardManifestDetailProps): JSX.Element => {

    const { scratchCardManifestId } = useParams();

    const { data, isLoading, isSuccess, isError } = useScratchCardManifest(scratchCardManifestId as string);

    const render = useCallback(() => {
        if (isSuccess) {
            return (
                <>
                    <Form initValues={data.data}>
                        <Form.Input field="title"/>
                        <Form.Input field="description"/>
                    </Form>
                </>
            )
        } else if (isLoading) {
            return <Spin/>
        } else if (isError) {
            return (
                <>
                    <Typography.Text>
                        Something went wrong
                    </Typography.Text>
                </>
            )
        }
    }, [isLoading, isSuccess, isError])

    return (
        <>
            {render()}
        </>
    )
}

export default withPrimaryLayout(ScratchCardManifestDetail, {
    authenticated: true
});
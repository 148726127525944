import { Button, Form, Typography } from "@douyinfe/semi-ui";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";


interface NewRewardProps {};

const NewReward = (props: NewRewardProps): JSX.Element => {
    return (
        <>
          <Typography.Title>New Reward</Typography.Title>
            <Form>
                <Button>Create reward</Button>
            </Form>  
        </>
    )
}

export default withPrimaryLayout(NewReward);
import { useQuery } from "@tanstack/react-query"
import axios from "axios";
import { APIResponse, PayoutList } from "../lib/typings";


export const useUserPayoutsList = (userId: string, count: number = 20, cursor?: number) => {
    return useQuery<any, unknown, APIResponse<PayoutList>, any>({
        queryKey: ['users', userId, 'payouts', cursor],
        queryFn: async () => {
            return (await axios.get(`/admin/users/${userId}/payouts?count=${count}${cursor ? `&cursor=${cursor}`: ''}`)).data;
        }
    });
}
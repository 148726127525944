import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { APIResponse } from "../lib/typings"


export const useScratchCardManifestList = (count: number = 20, cursor?: number) => {
    return useQuery<any, any, APIResponse<any>, any>({
        queryKey: ['scratch-card-manifests', cursor],
        queryFn: async () => {
            return (await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/admin/scratch-card-manifests?count=${count}${cursor ? `&cursor=${cursor}`: ''}`)).data
        }
    })
}
import { Button, Form } from "@douyinfe/semi-ui";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";


interface VerificationDeclineButtonProps {
    userId: number;
};

const VerificationDeclineButton = (props: VerificationDeclineButtonProps): JSX.Element => {

    const { mutate, isLoading, isSuccess, isError } = useMutation({
        mutationKey: ['user-verification', props.userId],
        mutationFn: async () => {
          return (await axios.post(`/admin/user-verification/${props.userId}/decline`)).data
        }
      })

    const submit = () => {
        mutate()
    }

    return (
        <>
            <Form onSubmit={submit}>
                <Button htmlType="submit" disabled={isLoading}>Decline</Button>
            </Form>
        </>
    )
}

export default VerificationDeclineButton;
import { Button, Card, Col, Row, Typography } from "@douyinfe/semi-ui";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";


interface NotificationListProps { };

const NotificationList = (props: NotificationListProps): JSX.Element => {
    return (
        <>
            <Helmet>
                <title>Notifications | Droplime</title>
            </Helmet>
            <Typography.Title>Notifications</Typography.Title>
            <main className="py-10">
                <Row gutter={24}>
                    <Col span={8}>
                        <Card title={`Inbox`} footer={<Button>Send</Button>}>
                            <Typography.Text>
                                Add a notification to a segment of user inboxes
                            </Typography.Text>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title={`Push notifications`} footer={(
                            <>
                                <NavLink to={`/dashboard/notifications/inbox/new`}>
                                    <Button>Send</Button>
                                </NavLink>
                            </>
                        )}>
                            <Typography.Text>Send a push notification to a segment of users</Typography.Text>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title={`Email`} footer={<Button>Send</Button>}>
                            <Typography.Text>Email a customer</Typography.Text>
                        </Card>
                    </Col>
                </Row>
            </main>
        </>
    )
}

export default withPrimaryLayout(NotificationList, {
    authenticated: true,
});
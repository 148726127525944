import { Typography } from "@douyinfe/semi-ui";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";


interface ChestDetailProps {};

/**
 * allows a user to view and update the details of a chest
 * @param props 
 * @returns 
 */
const ChestDetail = (props: ChestDetailProps): JSX.Element => {
    return (
        <>
           <Typography.Title>Chest information</Typography.Title> 
        </>
    )
}

export default withPrimaryLayout(ChestDetail, {
    authenticated: true
});
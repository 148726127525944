import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { APIResponse, TaskList } from "../lib/typings"


export const useUserTasksList = (userId: string, count: number = 20, cursor?: number) => {
    return useQuery<any, any, APIResponse<TaskList>>({
        queryKey: ['users', userId, 'tasks', cursor],
        queryFn: async () => {
            return (await axios.get(`/admin/users/${userId}/tasks?count=${count}${cursor ? `&cursor=${cursor}` : ''}`)).data
        }
    })
}
import { Helmet } from "react-helmet";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";


interface GameDetailProps {};

const GameDetail = (props: GameDetailProps): JSX.Element => {
    return (
        <>
            <Helmet>
                <title>Game detail | Droplime</title>
            </Helmet>       
        </>
    )
}

export default withPrimaryLayout(GameDetail, {
    authenticated: true,
});
import { Badge, Card, List, Spin, Tag, Typography } from "@douyinfe/semi-ui";
import { useUserPayoutsList } from "../hooks/useUserPayoutsList";
import { useCallback } from "react";
import { format } from "date-fns";


interface RecentUserPayoutsProps {
    userId: string;
};

const RecentUserPayouts = (props: RecentUserPayoutsProps): JSX.Element => {

    const { data, isLoading, isError, isSuccess, refetch } = useUserPayoutsList(props.userId, 5)

    const render = useCallback(() => {
        if (isLoading) {
            return <Spin/> 
        } else if (isSuccess) {
            if (data.success) {
                return (
                    <>
                        <List
                            dataSource={data.data}
                            renderItem={(item, idx) => (
                                <>
                                    <List.Item
                                        key={idx}
                                        extra={(
                                            <>
                                                <Tag>{item.payout_status}</Tag>
                                            </>
                                        )}
                                        main={(
                                            <>
                                                <article>
                                                    <div>
                                                        {item.payout_type}
                                                    </div>
                                                    <div>
                                                        {format(new Date(item.create_time as string), 'HH:mm, dd/MM/yyyy')}
                                                    </div>
                                                </article>
                                            </>
                                        )}
                                    />
                                </>
                            )}
                        />
                    </>
                )
            } else {
                return (
                    <>
                        <Typography.Text>Something went wrong.</Typography.Text>
                    </>
                )
            }
        } else if (isError) {
            return (
                <>
                    <Typography.Text>Unknown error</Typography.Text>
                </>
            )
        }
    }, [isLoading, isError, isSuccess])

    return (
        <>
            <Card title={`Recent payouts`}>
                {render()}
            </Card>
        </>
    )
}

export default RecentUserPayouts;
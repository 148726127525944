import axios from "axios";

const baseUrl = process.env.REACT_APP_API_ENDPOINT;

export const make = async () => {

}

export const signIn = async (body: object) => {
    const response = await fetch(`${baseUrl}/auth/token`, {
        method: `POST`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': `application/json`
        },
        body: JSON.stringify(body)
    })
    if (response.status === 200) {
        return await response.json();
    } else {
        throw new Error("failed to sign in")
    }
}

export const listUsers = async (count?: number, cursor?: number) => {
    return await fetch(`${baseUrl}/users`)
}

export const getUser = async (userId: number) => {
    const res = await fetch(`${baseUrl}/admin/users/${userId}`, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('__token__')}`
        }
    })
    return await res.json();
}

export const listRaffles = async (count: number = 10, cursor?: number): Promise<any> => {
    const res = await fetch(`${baseUrl}/admin/raffles?count=${count}&cursor=${cursor}`, {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem('__token__')}`
        }
    });
    return await res.json();
}

export const newRaffle = async (input: any) => {

}

export const newLottery = async () => {}

export const newGameManifest = async () => {}

export const newInventoryItemManifest = async () => {}


import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { APIResponse, PayoutList } from "../lib/typings"


export const usePayoutList = (count: number = 20, cursor?: number) => {
    return useQuery<any, any, APIResponse<PayoutList>, any>({
        queryKey: ['payouts', cursor],
        queryFn: async () => {
            return axios.get(`/admin/payouts?count=${count}${cursor ? `&cursor=${cursor}` : ''}`)
        }
    })
}
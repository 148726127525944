import { Button, Typography } from "@douyinfe/semi-ui";
import { NavLink } from "react-router-dom";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";


interface ChestListProps { };

/**
 * displays a list of chests
 * @param props 
 * @returns 
 */
const ChestList = (props: ChestListProps): JSX.Element => {
    return (
        <>
            <Typography.Title>Chests</Typography.Title>
            <NavLink to={`/dashboard/chests/new`}>
                <Button>New chest</Button>
            </NavLink>
        </>
    )
}

export default withPrimaryLayout(ChestList, {
    authenticated: true
});
import { Button, Typography } from "@douyinfe/semi-ui";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { usePromoCodeList } from "../../hooks/usePromoCodeList";


interface PromoCodeListProps { };

const PromoCodeList = (props: PromoCodeListProps): JSX.Element => {

    const [page, setPage] = useState<number | undefined>(undefined);

    const {
        isLoading,
        isError,
        error,
        data,
        isFetching,
        isPreviousData,
    } = usePromoCodeList(page);

    return (
        <>
            <div className="pb-5">
                <Typography.Title>Promo Codes</Typography.Title>
            </div>
            <NavLink to={`/dashboard/promo-codes/new`}>
                <Button>New Promo Code</Button>
            </NavLink>
        </>
    )
}

export default withPrimaryLayout(PromoCodeList, {
    authenticated: true,
});
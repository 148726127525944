import { Helmet } from "react-helmet";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";
import { useGameCreate } from "../../hooks/useGameCreate";
import { Button, Card, Form, Typography } from "@douyinfe/semi-ui";


interface NewGameProps { };

/**
 * Allows a user to register a new game on the server
 */
const NewGame = (props: NewGameProps): JSX.Element => {

    const { mutate, isError, isLoading, isSuccess } = useGameCreate();

    const submit = (values: any) => {
        alert(values);
    }

    return (
        <>
            <Helmet>
                <title>New game</title>
            </Helmet>
            <main className="w-2/3 mx-auto space-y-4">
                <Typography.Title>New Game</Typography.Title>
                <Form onSubmit={submit}>
                    <Card title={`General information`}>
                        <Form.Input 
                            label={`Name`} 
                            field={`name`}
                            required
                        />
                        <Form.TextArea 
                            label={`Description`} 
                            field={`description`}
                            required
                        />
                        <Form.Input 
                            label={`URL`} 
                            field={`url`}
                            required
                        />
                        <Form.Upload 
                            label={`Thumbnail`} 
                            listType="picture"
                            limit={1}
                            multiple={false}
                            accept={`image/*`}
                            action={`/`} 
                            field={`thumbnail`}
                        />
                    </Card>
                    <Button htmlType="submit" className="mt-4">Create game</Button>
                </Form>
            </main>
        </>
    )
}

export default withPrimaryLayout(NewGame, {
    authenticated: true
});
import { useParams } from "react-router-dom";
import { useUserVerification } from "../../hooks/useUserVerification";
import { useCallback } from "react";
import { Card, Form, Image, Spin, TextArea, Typography } from "@douyinfe/semi-ui";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";
import VerificationDeclineButton from "./VerificationDeclineButton";
import VerificationAcceptButton from "./VerificationAcceptButton";


interface UserVerificationDetailProps { };

const UserVerificationDetail = (props: UserVerificationDetailProps): JSX.Element => {

    const { userId } = useParams();

    const { data, isLoading, isError, isSuccess } = useUserVerification(userId as string);

    const render = useCallback(() => {
        if (isSuccess) {
            if (data.success) {
                return (
                    <>
                        <Form initValues={data.data} className="space-y-4">
                            <TextArea value={data.data?.kyc_error_notes}/>
                            <Card title={`User information`}>
                                <Form.Input label={`First name`} field={`first_name`} disabled />
                                <Form.Input label={`Last name`} field={`last_name`} disabled />
                                <Form.Input label={`Nationality`} field={`nationality`}/>
                                <Form.Input label={`Date of birth`} field={`date_of_birth`}/>
                                <Form.Input label={`Marital status`} field={`marital_status`}/>
                                <Form.Input label={`Gender`} field={`gender`}/>
                            </Card>
                            <Card title={`Submitted information`}>
                                <Form.Input label={`Identification type`} field={`identification_type`} disabled />
                                <Image src={data.data?.id_passport}/>
                                <Image src={data.data?.selfie_url}/>
                            </Card>
                        </Form>
                        <section className={`flex justify-start gap-x-3`}>
                            <VerificationDeclineButton userId={data.data?.id as number}/>
                            <VerificationAcceptButton userId={data.data?.id as number}/>
                        </section>
                    </>
                )
            } else {
                return <Typography.Text>{data.message}</Typography.Text>
            }
        } else if (isError) {
            return <Spin />
        } else {
            return (
                <>
                    <Typography.Text>Something went wrong. Please try again.</Typography.Text>
                </>
            )
        }
    }, [isLoading, isError, isSuccess])

    return (
        <>
            {render()}
        </>
    )
}

export default withPrimaryLayout(UserVerificationDetail, {
    authenticated: true
});
import { Form } from "@douyinfe/semi-ui";


interface DistributionTypeSelectProps {
    field: string;
};

const DistributionTypeSelect = (props: DistributionTypeSelectProps): JSX.Element => {
    return (
        <>
            <Form.Select 
                label={`Distribution type`} 
                placeholder="Choose one" 
                field={props.field}
                className="w-full"
            >
                <Form.Select.Option value={`discrete`}>Discrete</Form.Select.Option>
                <Form.Select.Option value={`discrete_range`}>Discrete Range</Form.Select.Option>
                <Form.Select.Option value={`continuous`}>Continuous</Form.Select.Option>
                <Form.Select.Option value={`weighted_interval`}>Weight Interval</Form.Select.Option>
            </Form.Select>
        </>
    )
}

export default DistributionTypeSelect;
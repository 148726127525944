import React from 'react';
import logo from './logo.svg';
import './App.css';
import { ConfigProvider, LocaleProvider, Typography } from "@douyinfe/semi-ui";
import en_GB from "@douyinfe/semi-ui/lib/es/locale/source/en_GB";
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";
import LoginPage from './pages/Login';
import Dashboard from './pages/Dashboards/HomeDashboard';
import UserList from './pages/Users/UserList';
import UserDetail from './pages/Users/UserDetail';
import Payouts from './pages/Payouts/Payouts';
import PayoutDetail from './pages/Payouts/PayoutDetail';
import RaffleList from './pages/Raffles/RaffleList';
import RaffleDetail from './pages/Raffles/RaffleDetail';
import NewRaffle from './pages/Raffles/NewRaffle';
import RevenueDashboard from './pages/Dashboards/RevenueDashboard';
import AppContextProvider from './context/AppContextProvider';
import Rewards from './pages/Rewards/RewardsList';
import NewReward from './pages/Rewards/NewReward';
import axios from 'axios';
import NotificationList from './pages/Notifications/NotificationList';
import NewNotification from './pages/NewNotification';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import ChestList from './pages/Chests/ChestList';
import NewChest from './pages/Chests/NewChest';
import ChestDetail from './pages/Chests/ChestDetail';
import NewPromoCode from './pages/PromoCodes/NewPromoCode';
import PromoCodeDetail from './pages/PromoCodes/PromoCodeDetail';
import PromoCodeList from './pages/PromoCodes/PromoCodeList';
import NewLottery from './pages/Lotteries/NewLottery';
import LotteryDetail from './pages/Lotteries/LotteryDetail';
import LotteryList from './pages/Lotteries/LotteryList';
import GamesList from './pages/Games/GamesList';
import NewGame from './pages/Games/NewGame';
import GameDetail from './pages/Games/GameDetail';
import NewInboxNotification from './pages/Notifications/NewInboxNotification';
import NewPushNotification from './pages/Notifications/NewPushNotification';
import NewEmailNotification from './pages/Notifications/NewEmailNotification';
import ScratchCardManifestDetail from './pages/ScratchCards/ScratchCardManifestDetail';
import NewScratchCardManifest from './pages/ScratchCards/NewScratchCardManifest';
import ScratchCardManifestList from './pages/ScratchCards/ScratchCardManifestList';
import UserVerificationList from './pages/UserVerification/UserVerificationList';
import UserVerificationDetail from './pages/UserVerification/UserVerificationDetail';

const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/dashboard/games",
    element: <GamesList />,
  },
  {
    path: "/dashboard/games/new",
    element: <NewGame />,
  },
  {
    path: "/dashboard/games/:gameId",
    element: <GameDetail />,
  },
  {
    path: "/dashboard/revenue",
    element: <RevenueDashboard />,
  },
  {
    path: "/dashboard/users",
    element: <UserList />,
  },
  {
    path: "/dashboard/users/:userId",
    element: <UserDetail />,
  },
  {
    path: "/dashboard/notifications",
    element: <NotificationList />,
  },
  {
    path: "/dashboard/notifications/inbox/new",
    element: <NewInboxNotification />,
  },
  {
    path: "/dashboard/notifications/push/new",
    element: <NewPushNotification />,
  },
  {
    path: "/dashboard/notifications/email/new",
    element: <NewEmailNotification/>,
  },
  {
    path: "/dashboard/payouts/:payoutId",
    element: <PayoutDetail />,
  },
  {
    path: "/dashboard/payouts",
    element: <Payouts />,
  },
  {
    path: "/dashboard/chests",
    element: <ChestList />,
  },
  {
    path: "/dashboard/chests/new",
    element: <NewChest />,
  },
  {
    path: "/dashboard/lotteries",
    element: <LotteryList />,
  },
  {
    path: "/dashboard/lotteries/new",
    element: <NewLottery />,
  },
  {
    path: "/dashboard/lotteries/:lotteryId",
    element: <LotteryDetail/>,
  },
  {
    path: "/dashboard/chests/:chestId",
    element: <ChestDetail />,
  },
  {
    path: "/dashboard/raffles",
    element: <RaffleList />,
  },
  {
    path: "/dashboard/raffles/new",
    element: <NewRaffle />,
  },
  {
    path: "/dashboard/raffles/:raffleId",
    element: <RaffleDetail />,
  },
  {
    path: "/dashboard/rewards",
    element: <Rewards />,
  },
  {
    path: "/dashboard/rewards/new",
    element: <NewReward />,
  },
  {
    path: "/dashboard/rewards/:id",
    element: <Rewards />,
  },
  {
    path: "/dashboard/promo-codes",
    element: <PromoCodeList />,
  },
  {
    path: "/dashboard/promo-codes/new",
    element: <NewPromoCode />,
  },
  {
    path: "/dashboard/promo-codes/:id",
    element: <PromoCodeDetail />,
  },
  {
    path: "/dashboard/scratch-card-manifests",
    element: <ScratchCardManifestList />,
  },
  {
    path: "/dashboard/scratch-card-manifests/new",
    element: <NewScratchCardManifest />,
  },
  {
    path: "/dashboard/scratch-card-manifests/:scratchCardManifestId",
    element: <ScratchCardManifestDetail />,
  },
  {
    path: "/dashboard/kyc",
    element: <UserVerificationList />,
  },
  {
    path: "/dashboard/kyc/:userId",
    element: <UserVerificationDetail />,
  },
  {
    path: "*",
    loader: async () => {
      return redirect('/login')
    },
    // element: (<><Typography.Text>Not found</Typography.Text></>)
  }
]);


const queryClient = new QueryClient()

function App() {

  axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common['Accept'] = 'application/json';

  return (
    <LocaleProvider locale={en_GB}>
      <QueryClientProvider client={queryClient}>
        <AppContextProvider>
          <RouterProvider router={router} />
        </AppContextProvider>
      </QueryClientProvider>
    </LocaleProvider>
  );
}

export default App;

import { Button, Typography } from "@douyinfe/semi-ui";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";


interface RaffleListProps { };

const RaffleList = (props: RaffleListProps): JSX.Element => {
    return (
        <>
            <Helmet>
                <title>Raffles | Droplime</title>
            </Helmet>
            <Typography.Title>Raffles</Typography.Title>
            <NavLink to={`/dashboard/raffles/new`}>
                <Button>New raffle</Button>
            </NavLink>
        </>
    )
}

export default withPrimaryLayout(RaffleList, {
    authenticated: true,
});
import { useQuery } from "@tanstack/react-query"
import axios from "axios"


export const usePayout = (payoutId: string) => {
    return useQuery({
        queryKey: ['payout', payoutId],
        queryFn: async () => {
            return (await axios.get(`/admin/payouts/${payoutId}`)).data;
        }
    })
}
import { useParams } from "react-router-dom";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";
import { usePayout } from "../../hooks/usePayout";


interface PayoutDetailProps {};

const PayoutDetail = (props: PayoutDetailProps): JSX.Element => {
    
    let { payoutId } = useParams();
    const { data, isLoading, isError, isFetched } = usePayout(payoutId as string);

    return (
        <>
            
        </>
    )
}

export default withPrimaryLayout(PayoutDetail, {
    authenticated: true,
});
import { useContext, useEffect } from "react";
import Navbar from "../components/Navbar";
import { useAppContext } from "../hooks/useAppContext";
import { useNavigate } from "react-router-dom";

export interface LayoutProps {
  authenticated?: boolean;
}

const withPrimaryLayout =
  (Wrapped: any, layout?: LayoutProps) => (props: any) => {

    const { state } = useAppContext();
    const navigate = useNavigate();
    if (layout?.authenticated && !state.authenticated) {
      if (process.env.NODE_ENV === 'production') {
        navigate('/login', { replace: true })
      }
    }

    return (
      <>
        <Navbar />
        <main className="py-3 md:p-10 my-92 md:py-10 md:mt-0">
          <Wrapped {...props} />
        </main>
      </>
    );
  };

export default withPrimaryLayout;

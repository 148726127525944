import { useParams } from "react-router-dom";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";
import { useLottery } from "../../hooks/useLottery";
import { Helmet } from "react-helmet";


interface LotteryDetailProps {};

/**
 * displays the details of a lottery
 */
const LotteryDetail = (props: LotteryDetailProps): JSX.Element => {

    const { lotteryId } = useParams();
    const { data, isError, isSuccess, isLoading } = useLottery(lotteryId as string);

    return (
        <>
            <Helmet>
                <title>Lottery Detail | Droplime</title>
            </Helmet>
        </>
    )
}

export default withPrimaryLayout(LotteryDetail, {
    authenticated: true,
});
import { Button, Form, List, Spin, Tag, Toast, Typography } from "@douyinfe/semi-ui";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";
import { Helmet } from "react-helmet";
import { useCallback } from "react";
import { usePayoutList } from "../../hooks/usePayoutList";
import { format } from "date-fns";


interface PayoutsListProps { };

const PayoutsList = (props: PayoutsListProps): JSX.Element => {

    const { data, isLoading, isError, isSuccess } = usePayoutList();

    const render = useCallback(() => {
        if (isSuccess) {
            Toast.info(data.message);
            if (data.success) {
                return (
                    <>
                        <List
                            dataSource={data.data}
                            renderItem={(item, idx) => (
                                <>
                                    <List.Item
                                        key={idx}
                                        main={(
                                            <>
                                                <article>
                                                    <Typography.Text strong>{item.payout_name}</Typography.Text>
                                                    <div>
                                                        <Typography.Text>{format(new Date(item.create_time as string), 'HH:mm, dd/MM/yyyy')}</Typography.Text>
                                                    </div>
                                                </article>
                                            </>
                                        )}
                                        extra={(
                                            <>
                                                <Tag>{item.payout_status}</Tag>
                                            </>
                                        )}
                                    />
                                </>
                            )}
                        />
                    </>
                )
            } else {
                return (
                    <>
                        <Typography.Text>{data.message}</Typography.Text>
                    </>
                )
            }
        } else if (isLoading) {
            return (
                <>
                    <Spin />
                </>
            )
        } else {
            return (
                <>
                    <Typography.Text>Something went wrong</Typography.Text>
                </>
            )
        }
    }, [])

    return (
        <>
            <Helmet>
                <title>Payouts | Droplime</title>
            </Helmet>
            <Typography.Title>Payouts</Typography.Title>
            <Typography.Text>Payouts sent to all users</Typography.Text>
            <section id={`filters`} className="py-4 flex justify-start gap-x-4">
                <Form className="flex justify-start my-auto gap-x-3">
                    <Form.Select field={`status`} label={`Status`} initValue={`all`} placeholder={`Status`}>
                        <Form.Select.Option value={`all`}>All</Form.Select.Option>
                        <Form.Select.Option value={`pending`}>Pending</Form.Select.Option>
                        <Form.Select.Option value={`failed`}>Failed</Form.Select.Option>
                        <Form.Select.Option value={`completed`}>Completed</Form.Select.Option>
                    </Form.Select>
                    <Form.DatePicker field={`fromDate`} label={`From`} format={`datetime`} />
                    <Form.DatePicker field={`untilDate`} label={`Until`} format={`datetime`} />
                    <Button type={`primary`} htmlType="submit">Filter</Button>
                </Form>
            </section>
            {render()}
        </>
    )
}

export default withPrimaryLayout(PayoutsList, {
    authenticated: true,
});
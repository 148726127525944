import { useMutation } from "@tanstack/react-query"
import axios from "axios"



export const useChestManifestCreate = () => {
    return useMutation({
        mutationKey: ['chest-manifests'],
        mutationFn: async (input: any) => {
            return (await axios.post(`/admin/chest-manifests`, JSON.stringify(input))).data;
        }
    })
}
import { useCallback } from "react";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";
import { useScratchCardManifestList } from "../../hooks/useScratchCardManifestList";
import { List, Spin, Typography } from "@douyinfe/semi-ui";
import { format } from "date-fns";
import { NavLink } from "react-router-dom";

interface ScratchCardManifestListProps { };

const ScratchCardManifestList = (props: ScratchCardManifestListProps): JSX.Element => {

    const { data, isLoading, isError, isSuccess } = useScratchCardManifestList();

    const render = useCallback(() => {
        if (isSuccess) {
            if (data.success) {
                return (
                    <>
                        <List
                            dataSource={data.data}
                            renderItem={((item, idx) => (
                                <>
                                    <List.Item
                                        key={idx}
                                        children={(
                                            <>
                                                <NavLink to={`/dashboard/scratch-card-manifests/${item.id}`}>
                                                    <article>
                                                        <div>
                                                            <Typography.Text>{item.title}</Typography.Text>
                                                        </div>
                                                        <div>
                                                            <Typography.Text>{format(new Date(item.create_time), 'dd:MM:yyyy')}</Typography.Text>
                                                        </div>
                                                    </article>
                                                </NavLink>
                                            </>
                                        )}
                                    />
                                </>
                            ))}
                        />
                    </>
                )
            } else {
                return (
                    <>
                        <Typography.Text>{data.message}</Typography.Text>
                    </>
                )
            }
        } else if (isLoading) {
            return <Spin />
        } else if (isError) {
            return (
                <>
                    <Typography.Text>Unknown error occurred.</Typography.Text>
                </>
            )
        }
    }, [isLoading, isError, isSuccess])

    return (
        <>
            <Typography.Title>Scratch card manifests</Typography.Title>
            {render()}
        </>
    )
}

export default withPrimaryLayout(ScratchCardManifestList, {
    authenticated: true,
});
import { Typography } from "@douyinfe/semi-ui";
import withPrimaryLayout from "../../hocs/withPrimaryLayout";
import { Helmet } from "react-helmet";


interface DashboardProps {};


/**
 * displays core application metrics and user information
 * @param props 
 * @returns 
 */
const Dashboard = (props: DashboardProps): JSX.Element => {
    return (
        <>
            <Helmet>
                <title>Dashboard - Home | Droplime</title>
            </Helmet>
            <Typography.Title>User growth</Typography.Title>
        </>
    )
}

export default withPrimaryLayout(Dashboard, {
    authenticated: true,
});